import { Typography } from 'antd';

import { DocumentationItem } from './DocumentationItem/DocumentationItem';

const { Title } = Typography;

export const DocumentationPage: React.FC = () => {
  const documentationList = [
    {
      title: 'МД.137-13',
      desc: 'Расчетное определение основных параметров противодымной вентиляции здании: Методические рекомендации к СП 7.13130.2013',
      file: 'МД.137-13 Расчетное определение основных параметров противодымной вентиляции зданий. Методические рекомендации к СП 7.13130.2013.pdf',
    },
    {
      title: 'СИТИС-СПН-1',
      desc: 'Пожарная нагрузка. Справочник (редакция 3 от 20.06.2014)',
      file: 'СИТИС_СПН_1_Пожарная нагрузка_Справочник_(редакция_3).pdf',
    },
    {
      title: 'СП 7.13130.2013',
      desc: 'Отопление, вентиляция и кондиционирование. Требования пожарной безопасности (с Изменениями N 1, 2)',
      file: 'СП_7_13130_2013_ОВиК_Требования_Пожарной_безопасности_(с_ИЗМ1_ИЗМ2).pdf',
    },
    {
      title: 'СП 60.13330.2020',
      desc: 'Отопление, вентиляция и кондиционирование воздуха СНиП 41-01-2003 (с Поправкой, с Изменениями N 1, 2)',
      file: 'СП_60_13330_2020_ОВиК_СНиП_41_01_2003_(с_поправкой_с_ИЗМ1_ИЗМ2).pdf',
    },
    {
      title: 'СП 485.1311500.2020',
      desc: 'Системы противопожарной защиты. Установки пожаротушения автоматические. Нормы и правила проектирования',
      file: 'СП_485_1311500_2020.pdf',
    },
    {
      title: 'ТР-5044',
      desc: 'Пожарная нагрузка. Обзор зарубежных источников',
      file: 'ТР_5044_Пожарная_нагрузка.pdf',
    },
    {
      title: 'Федеральный закон от 22.07.2008 N 123-ФЗ',
      desc: 'Технический регламент о требованиях пожарной безопасности (с изменениями на 25 декабря 2023 года)',
      file: 'ФЗ_123_ТР_о_требованиях_пожарной_безопасности.pdf',
    },
    {
      title: 'Основы теплопередачи',
      desc: 'Михеев М.А., Михеева И. М. Основы теплопередачи (Изд. 2-е, стереотип. М.: «Энергия», 1977)',
      file: 'Ebook_Основы_теплопередачи_Михеев.pdf',
    },
    {
      title: 'Пожар в помещении',
      desc: 'Молчадский И. С. Пожар в помещении (М.: ВНИИПО, 2005)',
      file: 'Ebook_Пожар в помещении_Молчадский.PDF',
    },
    {
      title:
        'Прогнозирование опасных факторов пожара в помещении: Учебное пособие',
      desc: 'Кошмаров Ю. А. Прогнозирование опасных факторов пожара в помещении: Учебное пособие (М.: Академия ГПС МВД России, 2000)',
      file: 'Ebook_Прогнозирование_опасных_факторов_пожара_в_помещении_Кошмаров.pdf',
    },
    {
      title: 'Справочник по гидравлическим сопротивлениям',
      desc: 'Идельчик И. Е. Справочник по гидравлическим сопротивлениям/Под. Ред. М. О. Штейнберга (3-е изд., перераб. и доп. М.: Машиностроение, 1992)',
      file: 'Ebook_Справочник_по_гидравличесим_сопротивлениям_Идельчик.djvu',
    },
    {
      title: 'NFPA 92-2012',
      desc: 'Standard for Smoke Control Systems, 2012 Edition',
      file: 'NFPA_92_12_Standard_for_Smoke_Control_Systems.pdf',
    },
    {
      title: 'NFPA 92-2024',
      desc: 'Standard for Smoke Control Systems, 2024 Edition',
      file: 'NFPA_92-24_Standard_for_Smoke_Control_Systems.pdf',
    },
  ];

  return (
    <div className='h-full overflow-auto'>
      <div className='p-6'>
        <div className='bg-white'>
          <div className='p-6'>
            <Title level={3}>Нормативная документация</Title>
            {documentationList.map((documentation) => (
              <DocumentationItem
                key={documentation.title}
                title={documentation.title}
                desc={documentation.desc}
                file={documentation.file}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
