import { useState } from 'react';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Badge, Button, Table, TableColumnsType } from 'antd';

import { IAirExchangeItem } from '@/core/redux/slices/airExchange/slice';

import { statusColors, StatusTypes } from '../AirExchangePage';

interface ITableData {
  data: IAirExchangeItem[] | undefined;
}

export const AirExchangeTable: React.FC<ITableData> = ({ data }) => {
  const [shortTable, setShortTable] = useState<boolean>(true);

  const columnsBasis: TableColumnsType<IAirExchangeItem> = [
    {
      title: 'Документ',
      dataIndex: 'normative_document_number',
      width: 150,
    },
    {
      title: 'Помещение',
      dataIndex: 'room',
      width: 190,
    },
    {
      title: 'Приток',
      dataIndex: 'supply',
      width: 200,
    },
    {
      title: 'Вытяжка',
      dataIndex: 'exhaust',
      width: 200,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      width: 140,
      render: (status: StatusTypes) => (
        <Badge
          color={statusColors[status]}
          text={status === '-' ? 'Без статуса' : status}
        />
      ),
    },
  ];

  const columnsExtra: TableColumnsType<IAirExchangeItem> = [
    {
      title: 'Температура в холодный период года, °С',
      dataIndex: 'temperature_cold_period',
      width: 150,
    },
    {
      title: 'Примечание из нормативов',
      dataIndex: 'regulation_note',
      width: 250,
    },
    {
      title: 'Название документа',
      dataIndex: 'document_name',
      width: 250,
    },
    {
      title: 'Пункт',
      dataIndex: 'clause',
      width: 100,
    },
    {
      title: 'Примечание для проектирования',
      dataIndex: 'design_note',
      width: 250,
    },
    {
      title: 'Дополнительная информация',
      dataIndex: 'additional_info',
      width: 250,
    },
  ];

  const columns = shortTable
    ? columnsBasis
    : [...columnsBasis, ...columnsExtra];

  columns.push({
    title: (
      <Button
        icon={<VerticalAlignBottomOutlined rotate={shortTable ? -90 : 90} />}
        onClick={() => setShortTable(!shortTable)}
      />
    ),
    width: 65,
  });

  return (
    <div className='w-full' onDoubleClick={() => setShortTable(!shortTable)}>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName='align-top'
        rowKey='id'
        pagination={false}
        scroll={{ x: '100%' }}
        sticky={{ offsetHeader: -24 }}
      />
    </div>
  );
};
