import { PropsWithChildren, ReactNode } from 'react';
import { Divider, Popover, Typography } from 'antd';
import { TooltipAlignConfig, TooltipPlacement } from 'antd/es/tooltip';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { tourSelectors } from '@/core/redux/slices/tour/selectors';
import { tourActions } from '@/core/redux/slices/tour/slice';

interface ITourItem {
  content: string | ReactNode;
  placement?: TooltipPlacement;
  className?: string;
  step: number;
  open?: boolean;
  autoAdjustOverflow?: boolean;
  align?: Object;
}

const { Text, Title } = Typography;

export const TourItem: React.FC<PropsWithChildren<ITourItem>> = ({
  children,
  content,
  placement,
  className,
  step,
  open = true,
  autoAdjustOverflow = true,
  align,
}) => {
  const isTourRuning = useAppSelector(tourSelectors.isTourRuning);
  const disatch = useAppDispatch();

  const handleStopTour = () => {
    disatch(tourActions.fetchChangeTour({ is_onboarded: true })); // true — отказаться от онбординга
  };

  const maxStep = 9;

  const renderContent = () => {
    return (
      <div>
        <div className='px-4 py-[5px]'>
          <Title level={5} className='!m-0'>
            Создайте свой первый расчет
          </Title>
        </div>
        <Divider className='m-0' />
        <div className='p-4'>
          <div className='mb-2'>
            <Text>{content}</Text>
          </div>
          <div className='flex justify-between'>
            <Text className='text-primary'>
              {step}/{maxStep}
            </Text>
            <Text
              type='secondary'
              className='cursor-pointer'
              onClick={handleStopTour}
            >
              Скрыть подсказки
            </Text>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Popover
      content={renderContent}
      placement={placement}
      destroyTooltipOnHide
      open={isTourRuning && open}
      overlayInnerStyle={{ padding: 0 }}
      overlayClassName={className}
      autoAdjustOverflow={autoAdjustOverflow}
      align={align}
    >
      {children}
    </Popover>
  );
};
