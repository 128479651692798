import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileDoneOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';

import { Footer } from '@/components/ui/Footer/Footer';
import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { useAppDispatch } from '@/core/redux/hooks';
import { tourActions } from '@/core/redux/slices/tour/slice';

import { NewsComponent } from './NewsComponent';

const { Text, Title, Paragraph } = Typography;

export const HomePage: React.FC = () => {
  const [aboutOpen, setAboutOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    listRef?.current?.scroll({
      top: !aboutOpen ? 0 : listRef?.current?.offsetHeight,
      behavior: 'smooth',
    });
  }, [aboutOpen]);

  return (
    <div className='h-full overflow-auto' ref={listRef}>
      <ContentWrapper>
        <div className='flex flex-col px-6 gap-2.5'>
          <div className='py-6'>
            <Title level={3}>
              Аэропро.Эксперт: профессиональный инструмент для быстрого и
              удобного проектирования
            </Title>
            <Paragraph>
              Автоматические расчёты систем вентиляции и независимый от расчётов
              подбор любого ОВиК оборудования без привязки к производителям –
              всё на одной платформе.
            </Paragraph>
            <div className='flex flex-wrap gap-2'>
              <Button type='primary' onClick={() => navigate('/objects')}>
                Перейти к расчетам
              </Button>
              <Button onClick={() => navigate('/air-exchange')}>
                Справочник воздухообмена
              </Button>
              <Button onClick={() => navigate('/tools')}>
                Подбор оборудования
              </Button>
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <Card
              className='bg-neutral border-[#D9D9D9]'
              classNames={{
                body: '!p-4',
              }}
            >
              <div className='flex justify-between gap-6 items-center'>
                <div className='flex flex-col gap-4'>
                  <Text className='font-semibold'>
                    Впервые с нами? Пройдите пошаговое введение
                  </Text>
                  <Text>
                    Мы проведём вас через все этапы, чтобы вы уверенно начали
                    пользоваться сервисом. Для более детального изучения
                    возможностей сервиса всегда доступна подробная инструкция с
                    примерами расчётов.
                  </Text>
                </div>

                <div className='flex flex-col gap-2'>
                  <Button
                    onClick={() =>
                      dispatch(
                        tourActions.fetchChangeTour({ is_onboarded: false })
                      )
                    }
                    type='primary'
                  >
                    Пошаговое обучение
                  </Button>
                  <Button
                    onClick={() =>
                      window.open('https://aeropro.expert/rukovodstvo-po')
                    }
                    type='link'
                  >
                    Читать руководство
                  </Button>
                </div>
              </div>
            </Card>
            <div className='flex gap-4 pb-6'>
              <Card
                className='bg-primary'
                classNames={{
                  body: '!p-4 flex justify-between h-full',
                }}
              >
                <div className='flex flex-col gap-2'>
                  <Title level={5}>
                    <span className='flex gap-2 text-white'>
                      <MessageOutlined /> Чат с экспертом
                    </span>
                  </Title>
                  <Paragraph className='text-white'>
                    Если у Вас возникли вопросы, связанные с расчётами в нашей
                    программе, нормативным документам, вашими проектам или любые
                    другие вопросы - получите консультацию эксперта в сфере ОВиК
                    через нашего бота в Telegram.
                  </Paragraph>
                  <div className='flex justify-end mt-auto'>
                    <Button
                      onClick={() =>
                        window.open('https://t.me/AeroPro_Expert_Bot')
                      }
                    >
                      Перейти в Телеграмм
                    </Button>
                  </div>
                </div>
              </Card>

              <Card
                className='bg-neutral border-[#D9D9D9]'
                classNames={{
                  body: '!p-4 flex justify-between h-full',
                }}
              >
                <div className='flex flex-col  gap-2'>
                  <Title level={5} className='flex gap-2 text-white'>
                    <FileDoneOutlined /> Предложить идею
                  </Title>
                  <Paragraph>
                    Делитесь своими впечатлениями или предлагайте идеи по
                    развитию сервиса, заполнив форму обратной связи. Мы
                    постараемся учесть все комментарии в будущих обновлениях!
                  </Paragraph>
                  <div className='flex justify-end'>
                    <Button
                      onClick={() =>
                        window.open(
                          'https://forms.yandex.ru/cloud/66895defc417f32b335c12d2/'
                        )
                      }
                    >
                      Оставить идею
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div className='py-6'>
            <NewsComponent />
          </div>
        </div>
        <Footer
          aboutOpen={aboutOpen}
          setAboutOpen={() => {
            setAboutOpen(!aboutOpen);
          }}
        />
      </ContentWrapper>
    </div>
  );
};
