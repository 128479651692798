import { Link } from 'react-router-dom';
import { Button, Card, Typography } from 'antd';

const { Text } = Typography;

interface IFooter {
  aboutOpen: boolean;
  setAboutOpen: () => void;
}

export const Footer: React.FC<IFooter> = ({ aboutOpen, setAboutOpen }) => {
  return (
    <div className='h-full'>
      <div className=' px-6 py-4 flex items-center gap-4'>
        <Link
          to='https://storage.yandexcloud.net/aeropro-static/user_agreement_aeropro_expert.pdf'
          target='_blank'
          rel='noreferrer'
        >
          <Text type='secondary' underline>
            Пользовательское соглашение
          </Text>
        </Link>
        <Link
          to='https://storage.yandexcloud.net/aeropro-static/privacypolicy_%20aeropro_expert.pdf'
          target='_blank'
          rel='noreferrer'
        >
          <Text type='secondary' underline>
            Политика конфиденциальности
          </Text>
        </Link>
        <Button className='m-0 p-0' onClick={setAboutOpen} type='link'>
          <span className='underline'>Подробнее о сервисе</span>
        </Button>
      </div>

      {aboutOpen && (
        <div className='p-6'>
          <Card
            className='bg-neutral border-[#D9D9D9]'
            classNames={{
              body: '!p-4',
            }}
          >
            <div>
              Аэропро.Эксперт — инструмент для расчётов вентиляционных систем и
              подбора оборудования. Решение разработано для специалистов и
              профессионалов, стремящихся повысить скорость, качество
              проектирования и оптимизировать рабочие процессы. На текущий
              момент программный комплекс включает базовый функционал для
              расчёта параметров систем противодымной вентиляции и проведения
              аэродинамических расчётов систем вентиляции (включая противодымные
              и общеобменные системы). Сервис не привязан к конкретным
              производителям оборудования, предоставляя полную свободу выбора
              проектных решений. Наша команда предлагает современные
              инструменты, призванные упростить сложные задачи и повысить вашу
              эффективность. Мы уверены, что вы оцените его возможности и
              удобство работы!
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
