import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import {
  airExchangeActions,
  IAirExchangeList,
  IAirExchangeListFetchPayload,
  INormativeDocsListFetchPayload,
} from './slice';

type AirExchangeListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/air_exchange',
  'get',
  '200'
>;

type UniqDocNumbersResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/air_exchange/unique_doc_numbers',
  'get',
  '200'
>;

type NormativeDocsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/air_exchange/docs',
  'get',
  '200'
>;

type DateOfVerificationResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/air_exchange/last_updated',
  'get',
  '200'
>;

function* fetchAirExchangeList(
  action: PayloadAction<IAirExchangeListFetchPayload>
): Generator<any, void, AirExchangeListResponse> {
  const { search, order_by, status, page, size, doc } = action.payload;

  yield put(airExchangeActions.setAirExchangeListLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/air_exchange', 'get', {
      query: { search, order_by, status, page, size, doc },
      ...authAdd(),
    });

    const airExchangeList: IAirExchangeList = response;

    yield put(airExchangeActions.setAirExchangeList(airExchangeList));
    yield put(airExchangeActions.setAirExchangeListLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on air exchange list fetching');
    yield put(airExchangeActions.setAirExchangeListLock(LoadingStatus.ERROR));
  }
}

function* fetchUniqDocNumbers(): Generator<any, void, UniqDocNumbersResponse> {
  try {
    const response = yield call(
      restCall,
      '/air_exchange/unique_doc_numbers',
      'get',
      {
        ...authAdd(),
      }
    );

    yield put(airExchangeActions.setUniqDocNumbers(response));
  } catch (error) {
    console.log('Error on air exchange "fetchUniqDocNumbers" fetching');
  }
}

function* fetchNormativeDocs(
  action: PayloadAction<INormativeDocsListFetchPayload>
): Generator<any, void, NormativeDocsResponse> {
  const { search, order_by, status, page, size } = action.payload;
  try {
    const response = yield call(restCall, '/air_exchange/docs', 'get', {
      query: { search, order_by, status, page, size },
      ...authAdd(),
    });

    yield put(airExchangeActions.setNormativeDocs(response));
  } catch (error) {
    console.log('Error on air exchange "fetchNormativeDocs" fetching');
  }
}

function* fetchDateOfVerification(): Generator<
  any,
  void,
  DateOfVerificationResponse
> {
  try {
    const response = yield call(restCall, '/air_exchange/last_updated', 'get', {
      ...authAdd(),
    });

    yield put(airExchangeActions.setDateOfVerification(response));
  } catch (error) {
    console.log('Error on air exchange "fetchDateOfVerification" fetching');
  }
}

export const airExchangeSagas = [
  takeLatest(airExchangeActions.fetchAirExchangeList, fetchAirExchangeList),
  takeLatest(airExchangeActions.fetchUniqDocNumbers, fetchUniqDocNumbers),
  takeLatest(airExchangeActions.fetchNormativeDocs, fetchNormativeDocs),
  takeLatest(
    airExchangeActions.fetchDateOfVerification,
    fetchDateOfVerification
  ),
];
