import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownOutlined, LeftOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Dropdown, Modal, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { Breadcrumbs } from '@/components/ui/Breadcrumbs/Breadcrumbs';
import { TourItem } from '@/components/ui/TourItem/TourItem';
import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { SystemType } from '@/core/enums/systemType';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemParamsSelectors } from '@/core/redux/slices/smokeExtraction/system/params/selectors';
import { systemParamsActions } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';
import { systemActions } from '@/core/redux/slices/smokeExtraction/system/slice';

import { useCurrentCalculation } from '../hooks/useCurrentCalculation';

const groupTypes: any = {
  Аэродинамика: [
    {
      type: 'AerodynamicsView',
      title: 'Аэродинамический расчет',
      description: `Расчет вентиляционных сетей общеобменной и противодымной вентиляции любой сложности. В расчете учитываются подсосы (утечки) воздуха и изменение температуры`,
    },
  ],
  'Вытяжная противодымная вентиляция': [
    {
      type: 'RemoveFromAdjacentToBurningView',
      title: 'Удаление продуктов горения из смежного с горящим помещения',
      description:
        'Расчет расхода и температуры удаляемых продуктов горения. Расчет осуществляется по МР ВНИИПО',
    },
    {
      type: 'RemovingFromBurning',
      title: 'Удаление продуктов горения из горящего помещения',
      description:
        'Расчет расхода и температуры удаляемых продуктов горения. Возможен расчет при нахождении очага пожара под галереей или при сообщении с горящим помещением через проем. Расчет осуществляется по МР ВНИИПО',
    },
    {
      type: 'CalculationOfSmokeRemovalDevicesView',
      title: 'Расчет количества дымоприемных устройств',
      description:
        'Расчет минимального количества дымоприемных устройств и минимального расстояния между ними. Расчет осуществляется в соответствии с NFPA 92-24 и СП 7.13130.2013',
    },
  ],
  'Приточная противодымная вентиляция': [
    {
      type: 'AirCompensation',
      title: 'Компенсирующая подача воздуха',
      description:
        'Расчет подачи воздуха для компенсации удаляемых продуктов горения. Расчет осуществляется по МР ВНИИПО. ',
    },
    {
      type: 'ElevatorShaftsView',
      title: 'Лифтовые шахты',
      description:
        'Расчет подачи воздуха в лифтовые шахты при различных архитектурных решениях. Расчет осуществляется по МР ВНИИПО',
    },
    {
      type: 'SmokeFreeStairwells',
      title: 'Лестничные клетки',
      description:
        'Расчет подачи воздуха в незадымляемые лестничные клетки типа Н2 при различных архитектурных решениях. Расчет осуществляется по МР ВНИИПО',
    },
    {
      type: 'FireSafeZones',
      title: 'Пожаробезопасные зоны',
      description:
        'Расчет подачи воздуха в пожаробезопасные зоны. Возможен расчет режимов: на открытую (одну и более) дверь; на закрытые двери. Расчет осуществляется по МР ВНИИПО',
    },
    {
      type: 'AirlockVestibules',
      title: 'Тамбур-шлюзы',
      description:
        'Расчет подачи воздуха в тамбур-шлюзы. Возможен расчет режимов: на открытую (одну и более) дверь; на закрытые двери. Расчет осуществляется по МР ВНИИПО',
    },
    {
      type: 'AirCurtains',
      title: 'Воздушные завесы',
      description:
        'Расчет подачи воздуха в сопловые аппараты воздушных завес. Расчет осуществляется по МР ВНИИПО. Без привязки к конкретному производителю.',
    },
  ],
  'Дымовые люки': [
    {
      type: 'RoofSmokeHatches',
      title: 'Дымовые люки в кровле',
      description:
        'Расчет минимально необходимого сечения дымовых люков. Расчет осуществляется по МР ВНИИПО. Имеется функция автоматического подбора дымовых люков',
    },
    {
      type: 'FacadeSmokeHatches',
      title: 'Дымовые люки на фасаде',
      description:
        'Расчет минимально необходимого сечения дымовых люков. Расчет осуществляется по МР ВНИИПО. Имеется функция автоматического подбора дымовых люков',
    },
  ],
  'Общеобменная вентиляция': [
    {
      type: 'ApartmentsAirExchangeView',
      title: 'Расчет воздухообмена квартир',
      description:
        'Расчет минимально необходимого воздухообмена для квартир МКД и одноквартирных домов. Расчет осуществляется в соответствии с СП 54.13330.2022 и СП 60.13330.2020',
    },
  ],
};

interface ISystemAddForm {
  name: string;
  type: SystemType;
}

interface IModalData {
  type: string;
  title: SystemType;
  description: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.mixed<SystemType>().oneOf(Object.values(SystemType)).required(),
});

export const SystemAddPage: React.FC = () => {
  const [isModalData, setIsModalData] = useState<IModalData | null>(null);
  const [sorter, setSorter] = useState<string>('asc');

  const results =
    sorter === 'asc'
      ? Object.keys(groupTypes).sort()
      : Object.keys(groupTypes).sort().reverse();

  // const systemTypes = useAppSelector(systemParamsSelectors.systemTypes);

  const routerParams = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const addSystemLock = useAppSelector(systemSelectors.addSystemLock);

  const currentCalculation = useCurrentCalculation({
    calculationID: Number(routerParams.calculationId),
  });

  const { Text, Title, Paragraph } = Typography;

  const methods = useForm<ISystemAddForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    const data: ISystemAddForm = methods.getValues();

    dispatch(
      systemActions.addSystem({
        calculation_id: Number(routerParams.calculationId),
        name: data.name,
        type: data.type,
      })
    );
  };

  useEffect(() => {
    dispatch(systemParamsActions.fetchSystemTypes());
  }, []);

  useEffect(() => {
    const { status, reponse } = addSystemLock;

    if (status === LoadingStatus.LOADED && reponse) {
      navigate(
        `/objects/${routerParams.id}/${routerParams.calculationId}/system/${reponse.id}`
      );
    }
  }, [addSystemLock]);

  useEffect(() => {
    return () => {
      dispatch(
        systemActions.setAddSystemLock({
          status: LoadingStatus.NEVER,
          reponse: null,
        })
      );
    };
  }, []);

  const showModal = (elem: IModalData) => {
    methods.setValue('type', elem?.title);
    setIsModalData(elem);
  };

  const handleCancel = () => {
    methods.reset();
    setIsModalData(null);
  };

  return (
    <div className='h-full overflow-auto'>
      <Breadcrumbs
        items={[
          {
            key: 'home',
            title: 'Все расчёты',
          },
          {
            key: 'objects',
            title: <Link to={'/objects'}>Объекты строительства</Link>,
          },
          ...(currentCalculation
            ? [
                {
                  key: 'currentObject',
                  title: (
                    <Link to={`/objects/${routerParams.id}`}>
                      {currentCalculation.object_name}
                    </Link>
                  ),
                },
                {
                  key: 'calculation',
                  title: (
                    <Link
                      to={`/objects/${routerParams.id}/${routerParams.calculationId}`}
                    >
                      {currentCalculation.calc_name}
                    </Link>
                  ),
                },
                {
                  key: 'system',
                  title: routerParams.id,
                },
              ]
            : []),
        ]}
      />
      <ContentWrapper>
        <div className='px-6'>
          <div className='px-2 py-4'>
            <div className='flex justify-between'>
              <div className='flex items-center gap-4'>
                <Button
                  icon={<LeftOutlined />}
                  onClick={() =>
                    navigate(
                      `/objects/${routerParams.id}/${routerParams.calculationId}`
                    )
                  }
                ></Button>
                <div className='flex flex-col'>
                  <Title level={5} className='!mb-0'>
                    Новый расчет
                  </Title>
                  <Text type='secondary'>Выберите тип расчета</Text>
                </div>
              </div>
              <div className='flex gap-2'>
                <Text type='secondary'>Сортировка по</Text>
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: 'Сортировка от А до Я',
                        key: 'Сортировка от А до Я',
                      },
                      {
                        label: 'Сортировка от Я до А',
                        key: 'Сортировка от Я до А',
                      },
                    ],
                    onClick: () => setSorter(sorter === 'asc' ? 'desc' : 'asc'),
                    selectable: true,
                  }}
                  trigger={['click']}
                >
                  <Text className='cursor-pointer hover:text-sky-500'>
                    категории
                    <Text type='secondary'>
                      <DownOutlined
                        style={{ fontSize: '11px', marginLeft: '5px' }}
                      />
                    </Text>
                  </Text>
                </Dropdown>
              </div>
            </div>
            <FormProvider {...methods}>
              <form>
                {isModalData && (
                  <Modal
                    title={isModalData?.title}
                    open={isModalData !== null}
                    centered
                    closable={false}
                    footer={
                      <div className=' gap-2 grid grid-cols-2'>
                        <Button onClick={handleCancel}>Отменить</Button>
                        <TourItem
                          content={
                            <div>
                              Почти готово! <br />
                              Введите название расчета и нажмите “Создать
                              расчет”
                            </div>
                          }
                          step={8}
                          placement='right'
                          open={isModalData !== null}
                        >
                          <Button
                            type='primary'
                            htmlType='submit'
                            onClick={onSubmit}
                            disabled={!methods.formState.isValid}
                          >
                            Создать расчет
                          </Button>
                        </TourItem>
                      </div>
                    }
                  >
                    <div className='flex flex-col gap-4'>
                      <Text type='secondary'>{isModalData?.description}</Text>
                      <InputForm
                        label='Название расчета'
                        name='name'
                        placeholder='Введите название расчета'
                      />
                    </div>
                  </Modal>
                )}
              </form>
            </FormProvider>

            <div className='flex flex-col gap-8'>
              <TourItem
                className='w-[300px]'
                content='Выберите Тип расчета. Это определит специфику вашего расчета.'
                step={7}
                placement='top'
                open={!isModalData}
              >
                <div></div>
              </TourItem>
              {results?.map((key: any, index: number) => {
                return (
                  <div key={key} className='flex flex-col gap-2'>
                    <Text type='secondary' className='ml-6'>
                      {key}
                    </Text>
                    {groupTypes?.[key]?.map((elem: any, ind: number) => (
                      <Card
                        key={ind}
                        className='border-[#D9D9D9] flex flex-col cursor-pointer group hover:bg-neutral '
                        classNames={{
                          body: '!py-3 px-4 ',
                        }}
                        onClick={() => showModal(elem)}
                      >
                        <div className='mb-2 flex justify-between'>
                          <Text className='text-[16px]'>{elem?.title}</Text>
                          <Text className='text-nowrap hidden text-[#1890ff] hover:text-[#69c0ff] group-hover:flex'>
                            Создать
                          </Text>
                        </div>

                        <div>
                          <Text type='secondary'>{elem?.description}</Text>
                        </div>
                      </Card>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
