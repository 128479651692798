import { useEffect, useRef, useState } from 'react';
import { Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { airExchangeSelectors } from '@/core/redux/slices/airExchange/selectors';
import { airExchangeActions } from '@/core/redux/slices/airExchange/slice';

import {
  AirExchangeFilter,
  IAirExchangeFilterForm,
} from './AirExchangeFIlter/AirExchangeFIlter';
import { AirExchangeList } from './AirExchangeList/AirExchangeList';

const { Text, Title } = Typography;

const defaultPageSize = 5;

export type StatusTypes =
  | 'Не определен'
  | 'Действует'
  | 'Не действует'
  | 'Действует. Отменен в части'
  | '-';

export const statusColors = {
  'Не определен': '#FAAD14',
  Действует: '#52C41A',
  'Действует. Отменен в части': '#52C41A',
  'Не действует': '#FF4D4F',
  '-': '#CCC',
};

const getMaxCurrentPage = (
  totalItems: number | null | undefined,
  itemsPerPage: number,
  currentPage: number
): number | undefined => {
  if (!totalItems) {
    return;
  }

  // Calculate the maximum possible page
  const maxPage = Math.ceil(totalItems / itemsPerPage);

  // If the current page exceeds the max page, return the max page
  if (currentPage > maxPage) {
    return maxPage;
  }

  // Otherwise, return the current page
  return currentPage;
};

export const AirExchangePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const airExchangeTotal = useAppSelector(
    airExchangeSelectors.airExchangeTotal
  );

  const normativeDocsTotal = useAppSelector(
    airExchangeSelectors.normativeDocsTotal
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showSize, setShowSize] = useState<number>(defaultPageSize);
  const [selectedView, setSelectedView] = useState<string>('items');
  const [typePage, setTypePage] = useState<string>('airExchange');

  const [filterValues, setFilterValues] = useState<IAirExchangeFilterForm>();

  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(airExchangeActions.fetchDateOfVerification());
    dispatch(airExchangeActions.fetchUniqDocNumbers());
  }, []);

  const getNewData = (current: number, size: number) => {
    setCurrentPage(current);
    setShowSize(size);
    const page =
      getMaxCurrentPage(
        typePage === 'regulatoryDocuments'
          ? normativeDocsTotal
          : airExchangeTotal,
        size,
        current
      ) || 1;

    if (typePage === 'regulatoryDocuments') {
      dispatch(
        airExchangeActions.fetchNormativeDocs({
          search: filterValues?.['search'],
          status: filterValues?.['status'],
          page: page,
          size: size,
        })
      );
    } else {
      dispatch(
        airExchangeActions.fetchAirExchangeList({
          search: filterValues?.['search'],
          status: filterValues?.['status'],
          page: page,
          size: size,
          doc: filterValues?.['doc'],
        })
      );
    }

    listRef?.current?.scroll({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    currentPage !== 1 && setCurrentPage(1);
    if (typePage === 'regulatoryDocuments') {
      dispatch(
        airExchangeActions.fetchNormativeDocs({
          search: filterValues?.['search'],
          status: filterValues?.['status'],
          page: 1,
          size: showSize,
        })
      );
    } else {
      dispatch(
        airExchangeActions.fetchAirExchangeList({
          search: filterValues?.['search'],
          status: filterValues?.['status'],
          doc: filterValues?.['doc'],
          page: 1,
          size: showSize,
        })
      );
    }

    listRef?.current?.scroll({ top: 0, behavior: 'smooth' });
  }, [filterValues, typePage]);

  return (
    <div className='h-full overflow-hidden flex flex-col'>
      <div className=''>
        <div className='p-6 flex flex-col gap-2 bg-white border border-[#D9D9D9]'>
          <AirExchangeFilter
            onFilterChange={(newValues) => setFilterValues(newValues)}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            filterValues={filterValues}
            typePage={typePage}
            setTypePage={setTypePage}
          />
        </div>
      </div>

      <div className='p-6 grow h-full overflow-auto' ref={listRef}>
        <div
          className={
            ['table', 'regulatoryDocuments']?.includes(selectedView)
              ? 'bg-white border border-[#D9D9D9]'
              : ''
          }
        >
          <div
            className={
              ['table', 'regulatoryDocuments']?.includes(selectedView)
                ? 'p-6'
                : ''
            }
          >
            <AirExchangeList
              currentPage={currentPage}
              onPaginationChange={(current, size) => getNewData(current, size)}
              selectedView={selectedView}
              typePage={typePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
