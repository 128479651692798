import { Badge, Table, TableColumnsType } from 'antd';

import { INormativeDocItem } from '@/core/redux/slices/airExchange/slice';

import { statusColors, StatusTypes } from './AirExchangePage';

interface IRegulatoryDocumentsTable {
  data: INormativeDocItem[] | undefined;
}

export const RegulatoryDocumentsTable: React.FC<IRegulatoryDocumentsTable> = ({
  data,
}) => {
  const columns: TableColumnsType<INormativeDocItem> = [
    {
      title: 'Документ',
      dataIndex: 'normative_document_number',
    },
    {
      title: 'Название документа',
      dataIndex: 'document_name',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      width: 140,
      render: (status: StatusTypes) => (
        <Badge
          color={statusColors[status]}
          text={status === '-' ? 'Без статуса' : status}
        />
      ),
    },
    {
      title: 'Примечание 1',
      dataIndex: 'additional_info_1',
    },
    {
      title: 'Примечание 2',
      dataIndex: 'additional_info_2',
    },
  ];

  return (
    <div className='w-full'>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName='align-top'
        rowKey='id'
        pagination={false}
        scroll={{ x: '100%' }}
        sticky={{ offsetHeader: -24 }}
      />
    </div>
  );
};
