import { useEffect, useState } from 'react';
import { Button, Divider, message, Select, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { equipmentRequestStatus } from '@/core/enums/equipmentRequestStatus';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { equipmentRequestSelectors } from '@/core/redux/slices/equipment/selectors';
import {
  equipmentRequestActions,
  IEquipmentRequestItem,
} from '@/core/redux/slices/equipment/slice';
import { formatToDate } from '@/core/utils/dateUtils';

import { errorMessage, successMessage } from '../helper';

const { Title, Text } = Typography;

interface IInfoTab {
  data: IEquipmentRequestItem | null;
}

interface IPayload {
  note?: string;
  is_urgent?: boolean;
}

export const InfoTab: React.FC<IInfoTab> = ({ data }) => {
  const [payload, setPayload] = useState<IPayload | null>({
    note: data?.note,
    is_urgent: data?.is_urgent,
  });
  const [messageApi, contextHolder] = message.useMessage();
  const status = data?.status ? equipmentRequestStatus[data?.status] : '';
  const updateEquipmentRequestLock = useAppSelector(
    equipmentRequestSelectors.updateEquipmentRequestLock
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (updateEquipmentRequestLock?.status === LoadingStatus.LOADED) {
      dispatch(equipmentRequestActions.setUpdateEquipmentRequestLock(null));
      successMessage(messageApi, 'Данные успешно сохранены');
    } else if (updateEquipmentRequestLock?.status === LoadingStatus.ERROR) {
      dispatch(equipmentRequestActions.setUpdateEquipmentRequestLock(null));
      errorMessage(
        messageApi,
        'Не удалось сохранить данные, что-то пошло не так'
      );
    }

    return () => {
      dispatch(equipmentRequestActions.setFilesList(null));
    };
  }, [updateEquipmentRequestLock]);

  const checkButton = () => {
    return (
      (payload?.note && payload?.note !== data?.note) ||
      payload?.is_urgent !== data?.is_urgent
    );
  };

  const handleChange = (key: string, value: string | boolean) => {
    setPayload((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const submit = () => {
    const result: IPayload = {};
    if (payload?.is_urgent !== data?.is_urgent)
      result.is_urgent = payload?.is_urgent;
    if (payload?.note !== data?.note) result.note = payload?.note;

    dispatch(
      equipmentRequestActions.updateEquipmentRequest({
        request_id: Number(data?.id),
        ...result,
      })
    );
  };

  return (
    <div className='pt-4 pb-4 grid grid-cols-1 gap-4 items-end h-full overflow-auto'>
      {contextHolder}
      <div className='flex flex-col gap-1 w-3/5'>
        <Title level={5}>Название объекта и проекта</Title>
        <Text type='secondary'>
          Объект: {data?.object_name} <Divider type='vertical' />
          Проект: {data?.project_name}
        </Text>
      </div>

      <div className='flex'>
        <div className='pr-2'>
          <Title level={5}>Срочность выполнения</Title>
          <Select
            className='w-[170px]'
            defaultValue={payload?.is_urgent}
            onChange={(e) => handleChange('is_urgent', e)}
            options={[
              { value: false, label: 'В обычном режиме' },
              { value: true, label: 'Срочно' },
            ]}
          />
        </div>
        <Divider className='h-auto ' type='vertical' />
        <div className='pl-2 pr-2'>
          <Title level={5}>Статус</Title>
          <Text type='secondary'>{status}</Text>
        </div>
        <Divider className='h-auto' type='vertical' />
        <div className='pl-1 '>
          <Title level={5}>Последнее изменение</Title>
          <Text type='secondary'>
            {data?.updated_at ? formatToDate(data?.updated_at) : ''}
          </Text>
        </div>
      </div>

      <label className='text-[16px] font-semibold'>
        Заметка к запросу (видно только вам)
      </label>
      <TextArea
        className='w-3/5'
        rows={4}
        placeholder='Расскажите подробнее'
        value={payload?.note || ''}
        onChange={(e) => handleChange('note', e?.target?.value)}
      />

      {checkButton() && (
        <Button type='primary' className='w-fit' onClick={submit}>
          Сохранить изменения
        </Button>
      )}

      <Divider className='m-0'></Divider>

      {data?.loyalty_program_participant && (
        <>
          <Title level={5}>Программа лояльности</Title>
          <div className='flex flex-col gap-1 w-3/5'>
            <Text type='secondary'>Номер участника программы</Text>
            <Text>{data?.loyalty_program_number}</Text>
          </div>
          <div className='flex flex-col gap-1 w-3/5'>
            <Text type='secondary'>Точный адрес объекта</Text>
            <Text>{data?.object_address}</Text>
          </div>
          <div className='flex flex-col gap-1 w-3/5'>
            <Text type='secondary'>Уточненное название</Text>
            <Text>{data?.specified_project_name}</Text>
          </div>
          <div className='flex flex-col gap-1 w-3/5'>
            <Text type='secondary'>Шифр объекта</Text>
            <Text>{data?.object_cipher}</Text>
          </div>
          <div className='flex flex-col gap-1 w-3/5'>
            <Text type='secondary'>Заказчик</Text>
            <Text>{data?.customer}</Text>
          </div>
          <div className='flex flex-col gap-1 w-3/5'>
            <Text type='secondary'>Генпроектировщик</Text>
            <Text>{data?.general_designer}</Text>
          </div>
          <div className='flex flex-col gap-1 w-3/5'>
            <Text type='secondary'>Стадия строительства</Text>
            <Text>{data?.construction_stage}</Text>
          </div>
          <Divider className='m-0'></Divider>
        </>
      )}
    </div>
  );
};
