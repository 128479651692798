import { Controller, useFormContext } from 'react-hook-form';
import { Select, SelectProps, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

interface SelectForm extends SelectProps {
  label?: string;
  name: string;
  defaultValue?: string | number;
  onChange?: (value: string, option: DefaultOptionType) => void;
}

export const SelectForm: React.FC<SelectForm> = (props) => {
  const { Text } = Typography;

  const { label, name, defaultValue, onChange } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];
  return (
    <>
      <div className='flex flex-col gap-1 w-full'>
        {label && (
          <label htmlFor={name} className='text-secondary'>
            {label}
          </label>
        )}
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              {...props}
              onChange={(value, option) => {
                field.onChange(value);
                props.onChange?.(value, option);
              }}
              status={error && 'error'}
            />
          )}
          control={control}
          name={name}
          defaultValue={defaultValue}
        />
      </div>

      {error && (
        <Text className='block' type='danger'>
          {String(error.message)}
        </Text>
      )}
    </>
  );
};
