import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.airExchange;

//list
const airExchangeList = createSelector(
  [selector],
  (state) => state.airExchangeList
);
const airExchangeItems = createSelector(
  [selector],
  (state) => state.airExchangeList?.items
);
const airExchangeTotal = createSelector(
  [selector],
  (state) => state.airExchangeList?.total
);

const airExchangeListLock = createSelector(
  [selector],
  (state) => state.airExchangeListLock
);

const uniqDocNumbers = createSelector(
  [selector],
  (state) => state.uniqDocNumbers
);

const normativeDocs = createSelector(
  [selector],
  (state) => state.normativeDocs
);

const normativeDocsTotal = createSelector(
  [selector],
  (state) => state.normativeDocs?.total
);

const dateOfVerification = createSelector(
  [selector],
  (state) => state.dateOfVerification
);

export const airExchangeSelectors = {
  airExchangeList,
  airExchangeListLock,
  airExchangeItems,
  airExchangeTotal,
  uniqDocNumbers,
  normativeDocs,
  normativeDocsTotal,
  dateOfVerification,
};
