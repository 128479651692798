import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DiffOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { Badge, Button, Divider, Tabs, Typography } from 'antd';
import { TabsProps } from 'antd/lib';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { equipmentRequestSelectors } from '@/core/redux/slices/equipment/selectors';
import { equipmentRequestActions } from '@/core/redux/slices/equipment/slice';

import { ChatTab } from './Tabs/ChatTab';
import { DocsTab } from './Tabs/DocsTab';
import { InfoTab } from './Tabs/InfoTab';

const { Title, Text } = Typography;

export const CurrentRequestPage: React.FC = () => {
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const equipmentRequest = useAppSelector(
    equipmentRequestSelectors.equipmentRequest
  );

  const msgCount = equipmentRequest?.new_msg_count;

  useEffect(() => {
    dispatch(
      equipmentRequestActions.fetchEquipmentRequest({
        request_id: Number(id),
      })
    );
    return () => {
      dispatch(equipmentRequestActions.setEquipmentRequest(null));
    };
  }, []);

  const itemsTabs: TabsProps['items'] = [
    {
      key: 'info',
      label: 'Информация о запросе',
      children: <InfoTab data={equipmentRequest} />,
      icon: <InfoCircleOutlined />,
    },
    {
      key: 'docs',
      label: 'История действий',
      children: <DocsTab data={equipmentRequest} />,
      icon: <DiffOutlined />,
    },
    {
      key: 'chat',
      label: (
        <span>
          Чат{' '}
          {msgCount !== 0 && (
            <Badge
              count={msgCount}
              color='#ece7e7'
              className='pb-[5px]'
              styles={{
                indicator: {
                  color: 'gray',
                },
              }}
            />
          )}
        </span>
      ),
      children: <ChatTab data={equipmentRequest} tab={tab} />,
      disabled: equipmentRequest?.status === 'DRAFT',
      icon: <MessageOutlined />,
    },
  ];

  const handleTabs = (tab: string) => {
    navigate(`/tools/${id}/${tab}`);
  };

  return (
    <div className='h-full overflow-auto'>
      <div className='p-6'>
        <div className='bg-white p-6'>
          <div className='flex gap-4 items-center'>
            <Button
              icon={<LeftOutlined />}
              onClick={() => navigate('/tools')}
            />
            <div>
              <Title level={5} className='!mb-0'>
                Запрос на подбор
              </Title>
              <Text style={{ fontSize: '13px' }}>
                ID {equipmentRequest?.id}
              </Text>
              {equipmentRequest?.loyalty_program_participant && (
                <>
                  <Divider type='vertical' />
                  <Text type='success' style={{ fontSize: '13px' }}>
                    Участвует в программе лояльности
                  </Text>
                </>
              )}
            </div>
          </div>
          {equipmentRequest && (
            <Tabs items={itemsTabs} onChange={handleTabs} activeKey={tab} />
          )}
        </div>
      </div>
    </div>
  );
};
