import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface IContentWrapper {
  bg?: string;
}

export const ContentWrapper: React.FC<PropsWithChildren<IContentWrapper>> = ({
  children,
  bg,
}) => {
  return (
    <div className='h-full'>
      <div className='p-6'>
        <div className={twMerge('bg-white', bg)}>{children}</div>
      </div>
    </div>
  );
};
