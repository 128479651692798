import { Pagination } from 'antd';

import { useAppSelector } from '@/core/redux/hooks';
import { airExchangeSelectors } from '@/core/redux/slices/airExchange/selectors';

import { RegulatoryDocumentsTable } from '../RegulatoryDocumentsTable';

import { AirExchangeItem } from './AirExchangeItem';
import { AirExchangeTable } from './AirExchangeTable';

interface IAirExchangeList {
  currentPage: number;
  onPaginationChange: (current: number, size: number) => void;
  selectedView: string;
  typePage: string;
}
export const AirExchangeList: React.FC<IAirExchangeList> = ({
  currentPage,
  onPaginationChange,
  selectedView,
  typePage,
}) => {
  const airExchangeItems = useAppSelector(
    airExchangeSelectors.airExchangeItems
  );
  const airExchangeTotal = useAppSelector(
    airExchangeSelectors.airExchangeTotal
  );

  const normativeDocs = useAppSelector(airExchangeSelectors.normativeDocs);
  const normativeDocsTotal = useAppSelector(
    airExchangeSelectors.normativeDocsTotal
  );

  const getTotal = () => {
    if (selectedView === 'regulatoryDocuments') {
      return normativeDocsTotal ? normativeDocsTotal : 0;
    } else {
      return airExchangeTotal ? airExchangeTotal : 0;
    }
  };

  return (
    <div className='flex flex-col gap-4'>
      {typePage === 'airExchange' && selectedView === 'items' && (
        <>
          {airExchangeItems && airExchangeItems?.length > 0 ? (
            airExchangeItems?.map((item) => (
              <AirExchangeItem key={item.id} {...item} />
            ))
          ) : (
            <div className='p-6 bg-white border  border-[#D9D9D9]'>
              Нет данных
            </div>
          )}
        </>
      )}

      {typePage === 'airExchange' && selectedView === 'table' && (
        <AirExchangeTable data={airExchangeItems} />
      )}

      {typePage === 'regulatoryDocuments' && (
        <RegulatoryDocumentsTable data={normativeDocs?.items} />
      )}

      {(airExchangeTotal || normativeDocsTotal) && (
        <div
          className={`flex justify-end bg-white ${selectedView === 'items' ? 'border border-[#D9D9D9] p-2' : ''}`}
        >
          <Pagination
            current={currentPage}
            total={getTotal()}
            onChange={onPaginationChange}
            defaultPageSize={5}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            showSizeChanger={true}
          />
        </div>
      )}
    </div>
  );
};
