import { Card, Typography } from 'antd';

const { Title, Paragraph } = Typography;

export const NewsComponent: React.FC = () => {
  return (
    <div>
      <Title level={3}>Что нового?</Title>

      <Card
        className='bg-neutral border-[#D9D9D9]'
        classNames={{
          body: '!p-4',
        }}
      >
        <Paragraph>
          В релизе февраля мы значительно улучшили Аэропро.Эксперт. Теперь
          доступен новый модуль подбора вентиляционного оборудования. Все
          запросы, корректировки, чаты, подборы, ХОВС и коммерческие
          предложения, включая предложения от нескольких производителей, теперь
          собраны в одном месте. Работайте быстрее и экономьте время с каждым
          запросом.
        </Paragraph>
        <Paragraph>
          Обновленный справочник воздухообмена стал еще удобнее. Добавлен
          табличный вид отображения информации, улучшена фильтрация для быстрого
          поиска нужных данных, обновлена информация по нормативным документам.
        </Paragraph>
        <div>А если вам чего-то не хватает – добавим по щелчку пальцев.</div>
      </Card>
    </div>
  );
};
