import React from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '@/components/ui/Footer/Footer';
import { Header } from '@/components/ui/Header/Header';
import { Sidebar } from '@/components/ui/Sidebar/Sidebar';
import { ModalRoot } from '@/modals/root';

export const MainLayout: React.FC = () => {
  return (
    <div className='h-screen w-screen bg-neutral overflow-hidden flex flex-col'>
      <Header />
      <div className='flex h-full overflow-hidden'>
        <Sidebar />
        <div className='flex-grow w-[calc(100vw_-_250px)]'>
          <div className='flex flex-col h-full'>
            <div className='grow h-full overflow-hidden'>
              <Outlet />
            </div>
            {/* <Footer /> */}
          </div>

          <ModalRoot />
        </div>
      </div>
    </div>
  );
};
