import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITourPayload {
  is_onboarded: boolean;
}

export interface ITourState {
  stepIndex: number;
  isTourRuning: boolean;
}

const initialState: ITourState = {
  stepIndex: 0,
  isTourRuning: false,
};

const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    startTour: (state) => ({
      ...state,
      isTourRuning: true,
    }),
    fetchChangeTour: (state, action: PayloadAction<ITourPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    stopTour: (state) => ({
      ...state,
      isTourRuning: false,
    }),
    setStep: (state, action: PayloadAction<number>) => ({
      ...state,
      stepIndex: action.payload,
    }),
    resetTour: (state) => ({
      ...state,
      stepIndex: 0,
    }),
  },
});

export const tourReducer = tourSlice.reducer;
export const tourActions = tourSlice.actions;
