import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import type oas from '@/services/rest/base/openapi';

import { appSettingsActions } from '../app/slice';

import { ITourPayload, tourActions } from './slice';

type tourStopRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/users/me/switch_onboarding',
  'post'
>;

function* fetchChangeTour(
  action: PayloadAction<ITourPayload>
): Generator<any, void, any> {
  const { is_onboarded } = action.payload;

  const request: tourStopRequest = {
    json: {
      is_onboarded: is_onboarded,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/users/me/switch_onboarding',
      'post',
      request
    );

    if (response?.is_onboarded) {
      yield put(tourActions.stopTour());
    } else {
      yield put(appSettingsActions.setSidebarShort(false));
      yield put(tourActions.startTour());
    }
  } catch (error: any) {
    console.log('Error on switch_onboarding "fetchChangeTour"', error);
  }
}

export const tourSagas = [
  takeLatest(tourActions.fetchChangeTour, fetchChangeTour),
];
