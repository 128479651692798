import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { Button, Segmented, SelectProps, Typography } from 'antd';
import { debounce } from 'lodash';

import { InputForm } from '@/components/form/Input';
import { SelectForm } from '@/components/form/Select';
import { AirExchangeStatus } from '@/core/enums/airExchangeStatus';
import { useAppSelector } from '@/core/redux/hooks';
import { airExchangeSelectors } from '@/core/redux/slices/airExchange/selectors';
import { formatToDate } from '@/core/utils/dateUtils';

const { Text, Title } = Typography;

export interface IAirExchangeFilterForm {
  search: string;
  status: string;
  doc?: string[];
}

interface IAirExchangeFilter {
  onFilterChange: (formValues: any) => void;
  selectedView: string;
  setSelectedView: (value: string) => void;
  filterValues: IAirExchangeFilterForm | undefined;
  setTypePage: (value: string) => void;
  typePage: string;
}
export const AirExchangeFilter: React.FC<IAirExchangeFilter> = ({
  onFilterChange,
  selectedView,
  setSelectedView,
  filterValues,
  setTypePage,
  typePage,
}) => {
  const airExchangeTotal = useAppSelector(
    airExchangeSelectors.airExchangeTotal
  );
  const normativeDocsTotal = useAppSelector(
    airExchangeSelectors.normativeDocsTotal
  );
  const uniqDocNumbers = useAppSelector(airExchangeSelectors.uniqDocNumbers);
  const dateOfVerification = useAppSelector(
    airExchangeSelectors.dateOfVerification
  );

  const uniqDocOptions = uniqDocNumbers?.map((elem) => {
    return { label: elem, value: elem };
  });

  const methods = useForm<IAirExchangeFilterForm>();

  const { watch } = methods;

  const debouncedSearch = debounce(onFilterChange, 700);

  useEffect(() => {
    const subscription = watch((value, { name, type }: any) => {
      if (name === 'search') {
        debouncedSearch(value);
      } else {
        onFilterChange(value);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const airExchangeStatusOptions: SelectProps['options'] = [
    {
      value: AirExchangeStatus.NOT_DEFIENED,
      label: AirExchangeStatus.NOT_DEFIENED,
    },
    { value: AirExchangeStatus.WORK, label: AirExchangeStatus.WORK },
    { value: AirExchangeStatus.NOT_WORK, label: AirExchangeStatus.NOT_WORK },
    { value: AirExchangeStatus.DECLINED, label: AirExchangeStatus.DECLINED },
    { value: '-', label: AirExchangeStatus.EMPTY },
  ];

  const checkResetFilters = () => {
    return Boolean(
      filterValues?.search || filterValues?.status || filterValues?.doc?.length
    );
  };

  const resetFilters = () => {
    onFilterChange(null);
    methods.reset();
  };

  const chengePage = () => {
    setTypePage(
      typePage === 'regulatoryDocuments' ? 'airExchange' : 'regulatoryDocuments'
    );
    setSelectedView(
      typePage === 'regulatoryDocuments' ? 'items' : 'regulatoryDocuments'
    );
    onFilterChange(null);
    methods.reset();
  };

  return (
    <div>
      <div className='flex justify-between'>
        <Title level={3} className='flex items-center gap-4'>
          {typePage === 'regulatoryDocuments'
            ? 'Перечень нормативных документов'
            : 'Справочник воздухообмена'}
          {dateOfVerification && (
            <Text type='secondary' className='font-normal'>
              Дата проверки: {formatToDate(dateOfVerification)}
            </Text>
          )}
        </Title>
        <Button
          type='link'
          className='underline p-0 h-fit justify-end'
          onClick={chengePage}
        >
          {typePage === 'regulatoryDocuments'
            ? 'Справочник воздухообмена'
            : 'Перечень нормативных документов'}
        </Button>
      </div>
      <FormProvider {...methods}>
        <form className='flex flex-col gap-4'>
          <div className='flex gap-4 w-full'>
            <InputForm
              name='search'
              placeholder={
                ['items', 'table']?.includes(selectedView)
                  ? 'Найти помещение или документ'
                  : 'Найти документ'
              }
              size='large'
              className='flex-grow w-full'
            />

            <div className='min-w-[240px]'>
              <SelectForm
                name='status'
                placeholder='Все статусы'
                options={airExchangeStatusOptions}
                size='large'
                allowClear
              />
            </div>

            {typePage !== 'regulatoryDocuments' && (
              <Segmented
                className='h-fit'
                size='large'
                value={selectedView}
                onChange={(value) => setSelectedView(value)}
                options={[
                  {
                    value: 'items',
                    icon: <AppstoreOutlined className='text-xl' />,
                  },
                  {
                    value: 'table',
                    icon: <BarsOutlined className='text-xl' />,
                  },
                ]}
              />
            )}
          </div>

          {typePage !== 'regulatoryDocuments' && (
            <SelectForm
              mode='multiple'
              name='doc'
              placeholder='Выбрать документы'
              options={uniqDocOptions}
              size='large'
              allowClear
            />
          )}
        </form>
      </FormProvider>
      <div className='flex gap-4 mt-2'>
        {(airExchangeTotal || normativeDocsTotal) && (
          <Text type='secondary'>
            {typePage === 'regulatoryDocuments'
              ? `${normativeDocsTotal} документов`
              : `${airExchangeTotal} вариантов`}
          </Text>
        )}
        {checkResetFilters() && (
          <Button
            type='link'
            className='underline p-0 h-fit'
            onClick={resetFilters}
          >
            Сбросить фильтры
          </Button>
        )}
      </div>
    </div>
  );
};
